import {Component} from 'vue-property-decorator'
import {VNode} from 'vue'

import BackendClientMixin from "@/mixins/BackendClientMixin"

import TableComponent from "@/components/TableComponent"

import {authStore} from "@/store"
import {activityStore, PaginationPayload, SortBy} from "../store/activity"

import * as ProposalRoutes from "@/_modules/proposal/router/proposal-demand-routes"
import * as DemandRoutes from "@/_modules/demand/router/routes"
import {BIcon, BIconArrowLeft, BIconArrowRight, BIconPlusCircle, BIconSortDown, BIconSortDownAlt} from "bootstrap-vue";

import {sortDates, stringifyWithFormat} from "@/utils/string-utils"
import {formatDate, sortObjectsByDates} from "@/utils/formatters"

import {OrderDirection} from "@/types"
import {DemandsSummary, DemandSummary, ProposalShortDetails} from "@/_modules/demand/types"
import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types"

import ApplicationConfiguration, {defaultPaginationItemsPerPage} from "@/constants/ApplicationConfiguration"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as Error from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants"
import {TasksDates} from "@/_modules/service/types"
import {ProposalItem, RequestItem} from "@/_modules/activity/assets/types";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import { getDealStatusColor } from '@/components/calendar/mixin'
import PageHeader from '@/components/layout/PageHeader'
import AppIconCaretDown from '@/components/icons/AppIconCaretDown'
import AppIconCaretUp from '@/components/icons/AppIconCaretUp'
import ContentSection from '@/components/layout/ContentSection'

export class DemandsErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = "err_user_does_not_have_profile"
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = "err_operation_is_not_authorized"
        break
      case ErrorCodes.UserDoesNotHaveRightToAccess:
        this.errorMessageKey = "err_user_does_not_have_rights_to_access"
        break
      case ErrorCodes.DemandNotFound:
        this.errorMessageKey = "err_demand_not_found"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}

const demandsErrorHandler = new DemandsErrorHandler()

const datesMaxDisplayedLen = 2


@Component({
  name: 'Demands',
  components: {
    BIcon,
    BIconArrowLeft,
    BIconArrowRight,
    BIconPlusCircle,
    BIconSortDown,
    BIconSortDownAlt
  }
})
export default class extends BackendClientMixin {

  public currentPage: number = 1
  public sortBy = SortBy.date
  public sortMode = OrderDirection.DESC

  public demands: DemandsSummary = {
    nr: 0,
    size: 10,
    items: [],
    total: 0
  }


  public get demandTableFields(): FieldLike[] {
    return [
      {
        key: 'name',
        label: this.translation('title_demand_title')
      },
      {
        key: 'createdAt',
        label: this.translation('title_demand_date'),
        formatter: formatDate
      },
      {
        key: 'proposals',
        label: this.translation('title_demand_proposals')
      },
      {
        key: 'active',
        label: this.translation('title_demand_status'),
        formatter: this.formatIsActiveStatus
      },
      {
        key: 'details',
        label: this.translation('title_demand_details'),
        class: 'border-left-0 px-0 app-table-row-collapse'
      }
    ]
  }

  public get proposalTableFields(): FieldLike[] {
    return [
      {
        key: 'providerName',
        label: this.translation('title_proposal_provider_name')
      },
      {
        key: 'dates',
        label: this.translation('title_proposal_date')
      },
      {
        key: 'price',
        label: this.translation('title_proposal_price'),
        class: 'text-right'
      },
      {
        key: 'status',
        label: this.translation('title_proposal_status')
      }
    ]
  }

  public async mounted(): Promise<void> {
    await this.fetchDemandsList()
  }

  public async fetchDemandsList(): Promise<void> {
    const payload: PaginationPayload = {
      userId: authStore.authInfo!.userId!,
      pagination: {
        page: this.demands.nr,
        size: this.demands.size
      },
      order: {
        sortBy: this.sortBy,
        sortMode: this.sortMode
      }
    }
    this.demands.nr = this.demands.nr -1
    this.demands = await this.withRequest(activityStore.getOrders(payload), demandsErrorHandler)

    this.demands.items.map(it => {
      sortObjectsByDates(it.proposals)
    })

    this.demands.items.map(it => {
      return it.proposals.map(iter => {
        return sortDates(iter.tasksDates)
      })
    })
  }

  public formatIsActiveStatus(value: string | undefined): string {
    return value ? this.translation('enum_demand_active') : this.translation('enum_demand_inactive')
  }

  public async redirectToProposal(item: ProposalItem): Promise<void> {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    const result = badges.find(it => it.docId === item.id)
    if (result !== undefined) {
      await this.withRequest(badgesStore.deleteBadgeCall(result.id), demandsErrorHandler).then(() => {
        badgesStore.removeInternalBadgeEvent(result)
      })
    }
    await this.$router.push({
      path: `${ProposalRoutes.ProposalRequester.path}/${item.id}`
    })
  }

  public renderStatus(demandProposal: TableRow<ProposalShortDetails>): VNode {
    return (
      <div class="d-flex align-items-baseline">
        <span class={`deal-status-indicator bg-${getDealStatusColor(demandProposal.item.status)} mr-2`} aria-hidden="true" />
        {this.translation(`lbl_status_${demandProposal.item.status}`)}
        {this.defineRelativeExactBadge(demandProposal)}
      </div>
    )
  }

  private defineRelativeListBadge(demand: TableRow<DemandSummary>): VNode | undefined {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    const result = badges.find((it) => {
      return demand.item.proposals.find(iter => iter.id === it.docId)
    })
    return (
      result !== undefined ? 
        <b-badge
          variant="danger"
          class="badge-event-label"
        >
          {this.translation('lbl_badge_new')}
        </b-badge> : undefined
    )
  }

  private defineRelativeExactBadge(demandProposal: TableRow<ProposalShortDetails>): (VNode | undefined)[] {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    return (
      badges.map((it) => {
        if (demandProposal.item.id === it.docId) {
          return (
            <b-badge
              variant="danger"
              class="badge-event-label"
            >
              {this.translation('lbl_badge_new')}
            </b-badge>
          )
        } else {
          return undefined
        }
      })
    )
  }

  public render(): VNode {
    return(
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            title={this.translation('menu_demands')}
            wideHeader={true}
          >
            <p>{this.translation('demands_description')}</p>
            <b-button
              variant="primary"
              to={`${DemandRoutes.DemandNewFromActivity.path}`}
            >
              <b-icon-plus-circle class="app-icon-lg mr-2" aria-hidden="true" />
              {this.translation("btn_add_demand")}
            </b-button>
          </PageHeader>
        </b-row>
        <ContentSection>
          <b-row>
            <b-col cols="12">
              <TableComponent
                bordered={false}
                busy={this.busy}
                class="app-table"
                current-page={this.demands.nr-1}
                empty-key="msg_table_empty"
                fields={this.demandTableFields}
                hover={false}
                id="demands-table"
                items={this.demands.items}
                per-page={defaultPaginationItemsPerPage}
                responsive="md"
                striped={false}
                thead-class="app-table-thead-bordered"
                scopedSlots={{
                  "head(name)": (row: TableRow<object>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.name
                            this.fetchDemandsList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.name) || this.sortBy !== SortBy.name ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "head(createdAt)": (row: TableRow<object>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.date
                            this.fetchDemandsList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.date) || this.sortBy !== SortBy.date ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "head(details)": (row: TableRow<never>) => {
                    return (
                      <div class="sr-only">{row.field.label}</div>
                    )
                  },
                  "cell(name)": (demand: TableRow<DemandSummary>) => {
                    return (
                      <div class="ellipsis-string-container" v-b-tooltip={{title: demand.value.length > ApplicationConfiguration.titleCropMidLength ? demand.value : '', variant: 'primary'}}>
                        <b-link class={demand.detailsShowing ? 'text-white' : 'text-primary'} to={`${DemandRoutes.DemandEditFromActivity.path}/${demand.item.id}`}>
                          {demand.value}
                        </b-link>
                      </div>
                    )
                  },
                  "cell(proposals)": (demand: TableRow<DemandSummary>) => {
                    return (
                      <div>
                        {demand.item.proposalsCount > 0 &&
                        <b-link aria-label={this.translation('title_offer_details')} class={demand.detailsShowing ? 'text-white' : 'text-primary'} onClick={() => (demand.toggleDetails())}>
                          {demand.item.proposals.length}
                          {this.defineRelativeListBadge(demand)}
                        </b-link>
                        }
                        {demand.item.proposalsCount === 0 &&
                        <span>-</span>
                        }
                      </div>
                    )
                  },
                  "cell(details)": (demand: TableRow<DemandSummary>) => {
                    return (
                      <div>
                        {demand.item.proposalsCount > 0 &&
                        <b-link aria-label={this.translation('title_offer_details')} class={demand.detailsShowing ? 'text-white' : 'text-primary'} onClick={() => (demand.toggleDetails())}>
                          {!demand.detailsShowing &&
                            <AppIconCaretDown class="app-icon-lg" />
                          }
                          {demand.detailsShowing &&
                            <AppIconCaretUp class="app-icon-lg" />
                          }
                        </b-link>
                        }
                        {demand.item.proposalsCount === 0 &&
                        <span></span>
                        }
                      </div>
                    )
                  },
                  "row-details": (demand: TableRow<DemandSummary>) => {
                    return (
                      <TableComponent
                        bordered={false}
                        busy={this.busy}
                        empty-key=''
                        fields={this.proposalTableFields}
                        hover={true}
                        items={demand.item.proposals}
                        responsive="md"
                        selectable
                        striped={false}
                        wrapper-class="mb-0"
                        onRowClicked={(item: RequestItem) => {
                          this.redirectToProposal(item)
                        }}
                        scopedSlots={{
                          "cell(providerName)": (demandProposal: TableRow<ProposalShortDetails>) => {
                            const fullName = `${demandProposal.item.providerName.first} ${demandProposal.item.providerName.last}`
                            return (
                              <div class="ellipsis-string-container" v-b-tooltip={{title: fullName.split(' ').join('').length > ApplicationConfiguration.nameCropMidLength ? fullName : '', variant: 'primary'}}>
                                <span>{fullName}</span>
                              </div>
                            )
                          },
                          "cell(dates)": (demandProposal: TableRow<ProposalShortDetails>) => {
                            const dates = demandProposal.item.tasksDates as TasksDates[] as string[]
                            return (
                              <div class="ellipsis-string-container" v-b-tooltip={{title: dates.length > datesMaxDisplayedLen ? stringifyWithFormat(dates) : '', variant: 'primary'}}>
                                <span>{stringifyWithFormat(dates)}</span>
                              </div>
                            )
                          },
                          "cell(price)": (demandProposal: TableRow<ProposalShortDetails>) => {
                            return (
                              <span>{demandProposal.item.totalPrice.amount} {this.translation(`enum_currency_${demandProposal.item.totalPrice.currency}`)}</span>
                            )
                          },
                          "cell(status)": (demandProposal: TableRow<ProposalShortDetails>) => {
                            return (
                              <div>
                                {this.renderStatus(demandProposal)}
                              </div>
                            )
                          }
                        }}
                      />
                    )
                  }
                }}
              />
            </b-col>
            {this.demands.total > defaultPaginationItemsPerPage &&
              <b-col cols="12">
                <b-pagination
                  align="center"
                  aria-controls="demands-table"
                  class="mt-6 mt-md-10 mb-0"
                  first-number
                  last-number
                  pills
                  per-page={defaultPaginationItemsPerPage}
                  total-rows={this.demands.total}
                  value={this.currentPage}
                  onChange={(v: number) => {
                    this.demands.nr = v - 1
                    this.currentPage = v
                    this.fetchDemandsList()
                  }}
                >
                  <template slot="prev-text"><b-icon-arrow-left /></template>
                  <template slot="next-text"><b-icon-arrow-right /></template>
                </b-pagination>
              </b-col>
            }
          </b-row>
        </ContentSection>
      </b-container>
    )
  }
}
