import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconCaretUp'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" class="app-icon" viewBox="0 0 24 24">
        <path fill="currentColor" fill-rule="evenodd" d="m7 14 5-5 5 5H7z" clip-rule="evenodd"/>
      </svg>
    )
  }
}
