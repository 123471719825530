import moment from 'moment'
import {ServiceType, ServiceTypeSubCategoriesWithQualification} from "@/_modules/service/types";
import {servicesStore} from "@/_modules/service/store/services";
import {Language, MapCoordinates, PriceForm} from "@/types";
import {ProposalShortDetails} from "@/_modules/demand/types";
import {isEmpty} from "@/constants/DataBoundaries";
import {ServerCfg} from "@/store/config";
import _ from "lodash";
import { appStore } from '@/store';
import { convertToI18nLang } from '@/store/app';
import { datepickerDisplayDateFormat } from '@/constants/ApplicationConfiguration';

/* eslint-disable */
interface ShortDetails extends Partial<ProposalShortDetails> {
}

export function formatDate(value: string | Date | undefined): string {
  if (value === undefined) {
    return 'N/A'
  }
  return moment(new Date(value)).locale(convertToI18nLang(appStore.locale)).format('L')
}

export function getDatepickerDisplayDateFormat(): string {
  return appStore.locale === Language.ENG ? 'MM/dd/yyyy' : datepickerDisplayDateFormat
}

function isAboveKilo(value: number | undefined): boolean | undefined {
  const kilo = 1000
  return value === undefined ? undefined : value > kilo
}

function distanceFormat(value: number | undefined): string | undefined {
  if (value !== undefined) {
    const dec = 10
    const hun = 100
    
    return (Math.round(Number(value) / dec ) / hun).toLocaleString(appStore.locale)
  }

  return undefined
}

export function distanceFormatter(dist: number | undefined, strM: string, strKm: string): string {
  if (isAboveKilo(dist) && dist !== undefined) {
    return distanceFormat(dist) + ' ' + strKm
  }

  if (dist !== undefined) {
    return Math.round(Number(dist)).toLocaleString(appStore.locale) + ' ' + strM
  }

  return "-"
}

export function getRelativeSubCategories(topLevelCategory: string, locale: Language, addEmpty?: boolean): ServiceTypeSubCategoriesWithQualification[] {
  //const translationPropertyName = getServiceTranslationPropertyName(locale)

  const serviceTypes = _.cloneDeep(servicesStore.serviceTypes)
  const emptyResult: ServiceType[] = [{
    category: '',
    category_et: 'Valige alamkategooria',
    category_ru: 'Выберите подкатегорию',
    category_en: 'Select subcategory',
    subCategories: []
  }]

  const res = serviceTypes.map((c1) => {
    return c1.subCategories.map((c2) => {
      if (c2.subCategories.length > 0) {
        if (c1.category === topLevelCategory) {
          return c2
        } else {
          return undefined
        }
      } else {
        if (c1.category === topLevelCategory) {
          return c1
        } else {
          return undefined
        }
      }
    })
  })

  /*_.uniq(_.compact(_.flatten(res))).sort(propertyStringComparator(translationPropertyName))
    .map(it => it.subCategories.sort(propertyStringComparator(translationPropertyName)))*/

  if (!addEmpty) {
    return _.uniq(_.compact(_.flatten(res)))
  }

  return emptyResult.concat(_.uniq(_.compact(_.flatten(res))))
}

export function getTopLevelCategories(locale: Language, addEmty?: boolean): ServiceType[] {
  //const translationPropertyName = getServiceTranslationPropertyName(locale)
  const topLevelCategories = servicesStore.serviceTypes.map(it => (it))

  const emptyOption: ServiceType[] = [{
    category: '',
    category_et: 'Valige alamkategooria',
    category_ru: 'Выберите подкатегорию',
    category_en: 'Select subcategory',
    subCategories: []
  }]

  //topLevelCategories.sort(propertyStringComparator(translationPropertyName))

  if(!addEmty) {
    return topLevelCategories
  }
  return emptyOption.concat(topLevelCategories)
}

export function getAllSubCategories(locale: Language, addEmpty?: boolean): ServiceTypeSubCategoriesWithQualification[] {
  //const translationPropertyName = getServiceTranslationPropertyName(locale)

  /*const category = servicesStore.serviceTypes
  const res = category.flatMap(it => {
    return it.subCategories.flatMap(iter => {
      return iter
    })
  })*/

  const serviceTypeWithTitle = servicesStore.serviceTypes.map(c1 => {
    if (_.compact(c1.subCategories.map(c2 => c2.subCategories.length)).length > 0) {
      return c1.subCategories.map(c2 => {
        return {
          category: c2.category,
          category_en: c2.category_en,
          category_et: c2.category_et,
          category_ru: c2.category_ru,
          subCategories: c2.subCategories.map(c3 => c3),
          qualification: c2.qualification
        }
      })
    } else {
      return {
        category: c1.category,
        category_en: c1.category_en,
        category_et: c1.category_et,
        category_ru: c1.category_ru,
        subCategories: c1.subCategories.map(c2 => c2),
      }
    }
  })

  const flatten: ServiceTypeSubCategoriesWithQualification[] = _.flatten(serviceTypeWithTitle)

  const emptyResult: ServiceTypeSubCategoriesWithQualification[] = [{
    category: '',
    category_et: 'Valige alamkategooria',
    category_ru: 'Выберите подкатегорию',
    category_en: 'Select subcategory',
    subCategories: []
  }]

  //flatten.sort(propertyStringComparator(translationPropertyName)).map(it => it).sort(propertyStringComparator(translationPropertyName))

  if (!addEmpty) {
    return flatten
  }

  return emptyResult.concat(flatten)
}

export function getRelativeTopLevelCategory(subCategory?: string): string {
  const res = servicesStore.serviceTypes.filter(it => {
    return it.subCategories.find(iter => iter.category === subCategory)
  })
  if (res[0] !== undefined) {
    return res[0].category
  } else {
    return ''
  }
}


function rad(x: number): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return x * Math.PI / 180
}

export function getDistance(p1: MapCoordinates, p2: MapCoordinates): number {

  const R = 6371e3; // Earth’s mean radius in meter
  const dLat = rad(p2.latitude - p1.latitude);
  const dLong = rad(p2.longitude - p1.longitude);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export function sortObjectsByDates(arr: ShortDetails[]): ShortDetails[] {
  return (
    arr.sort((left, right) => {
      return moment(left.tasksDates!.sort((a, b) => {
        return moment(a).toDate().getDate() - moment(b).toDate().getDate()
      })[0]).toDate().getDate() - moment(right.tasksDates![0]).toDate().getDate()
    })
  )
}

function decimalAdjust(value: number): string {
  if (isNaN(value)) {
    return '0.00';
  }
  const sv = value.toString().split('e')
  const exp = -2
  const result = Math.round(+(sv[0] + 'e' + (sv[1] ? (+sv[1] - exp) : -exp)))
  const formatted = result.toString().split('e')
  return String(+(formatted[0] + 'e' + (formatted[1] ? (+formatted[1] + exp) : exp)))
}

export function calculatePriceWithPercentage(serverConfig: ServerCfg | null, price?: PriceForm): string {
  if (serverConfig !== null && price !== undefined && !isEmpty(price.amount)) {
    const shift = 1 - Number(serverConfig.paymentFeeFixedPart)/10
    const paymentServiceEachTransactionFee = (Number(serverConfig.paymentFeeFixedPart) + Number(serverConfig.paymentFeeFixedPart) * Number(serverConfig.taxPercentage)/100)
    const priceForRequester = ((Number(price.amount) + paymentServiceEachTransactionFee)/shift)
    return Number(price.amount) === 0 ? '0.00' : decimalAdjust(priceForRequester)
  } else {
    return '0.00'
  }
}

export function calculatePriceWithoutPercentage(price: PriceForm, serverConfig: ServerCfg): string {
  if (!isEmpty(price.amount)) {
    const shift = 1 - Number(serverConfig.paymentFeeFixedPart)/10
    const paymentServiceEachTransactionFee = (Number(serverConfig.paymentFeeFixedPart) + Number(serverConfig.paymentFeeFixedPart) * Number(serverConfig.taxPercentage)/100)
    return decimalAdjust((Number(price.amount) * shift - paymentServiceEachTransactionFee)).toString()
  } else {
    return '0'
  }
}
/* eslint-enable */
