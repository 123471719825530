import { Component } from "vue-property-decorator"
import Vue, { VNode } from "vue"

@Component({name: 'ContentSection'})
export default class extends Vue {
  public render(): VNode {
    return (
      <b-card body-class="py-6 py-sm-12 py-md-16 px-lg-16">
        {this.$slots.default}
      </b-card>
    )
  }
}
