import {CalendarScheduleForm, TaskInternalOccurrence, InternalTaskData, OccurrenceEventState} from '@/_modules/request/types'
import {DealStatus, RecurrenceRule, ReminderOptions, RepeatBy, RepeatOptions} from '@/types'
import {ValidationObject, ValidationRuleSet} from '@/utils/vuelidate-extension'
import i18n from '@/i18n'

export const calendarErrors = {
  invalidDateOnSave: "err_schedule_invalid_date_on_save",
  invalidDateBeforeCreate: 'err_schedule_invalid_date_before_create',
  invalidDate: 'err_schedule_invalid_date',
  noDateBeforeNow: 'err_schedule_no_date_before_now',
  emptyCalendar: 'err_schedule_list_empty',
  notAllowedToEdit: "err_not_allowed_to_edit",
  endEarlyThenStart :"err_end_date_is_early_then_start"
}

export interface TimegridDisplayPrimaryTime {
  hour: number;
  minutes: number;
  hidden: boolean;
  fontWeight: string;
  color: string;
}

export interface SelectedPeriod {
  start: Date;
  end: Date;
}

export interface TzDate {
  _date: Date;
}

export interface BeforeCreateEvent {
  start: TzDate;
  end: TzDate;
  isAllDay: boolean;
  triggerEventName: string;
}

export interface BeforeUpdateOccurrenceData {
  changes: {
    end: {
      _date: TzDate;
    };
    start: {
      _date: TzDate;
    };
  };
  end: TzDate;
  start: TzDate;
  schedule: InternalTaskData;
}

export interface IncomeDataFromCalendar {
  body: string;
  start: TzDate;
  end: TzDate;
  title: string;
  id: string;
  bgColor: string;
  dealStatus: string;
  recurrenceRule: RecurrenceRule;
  occurrences: TaskInternalOccurrence[];
  state: OccurrenceEventState;
}

export interface SelectScheduleEvent {
  event: MouseEvent;
  schedule: IncomeDataFromCalendar;
}


export interface CalendarForm {
  task: CalendarScheduleForm;
  repeatBy: RepeatBy | undefined;
  timeFrom: string | undefined;
  timeTo: string | undefined;
}

export enum CalenderPeriod {
  Month = "month",
  Week = "week",
  Day = "day"
}

export interface RecurrenceRuleValidation extends ValidationObject<RecurrenceRule> {
  repeatType: ValidationRuleSet;
  repeatUntil: ValidationRuleSet;
  repeatCount: ValidationRuleSet;
}

export interface ScheduleValidation extends ValidationObject<CalendarScheduleForm> {
  recurrenceRule: RecurrenceRuleValidation;
}

export interface FormValidation extends ValidationObject<CalendarForm> {
  task: ScheduleValidation;
  timeFrom: ValidationRuleSet;
  timeTo: ValidationRuleSet;
}

export function buildFormInitialState(): CalendarForm {
  return {
    repeatBy: undefined,
    task: {
      id: undefined,
      calendarId: '0',
      category: 'time',
      bgColor: undefined,
      title: undefined,
      start: undefined,
      end: undefined,
      body: undefined,
      recurrenceRule: {
        repeatType: RepeatOptions.ONCE,
        repeatUntil: undefined,
        repeatCount: undefined
      },
      serviceCategories: [],
      state: {
        completed: undefined,
        confirmed: undefined,
        parentId: undefined,
        reminder: ReminderOptions.NONE
      }
    },
    timeFrom: undefined,
    timeTo: undefined
  }
}

export function getDealStatusColor(dealStatus: string | undefined): string {
  switch(dealStatus) {
    case DealStatus.Confirmed:
    case DealStatus.DealConcluded:
    case DealStatus.New:
    case DealStatus.Paid:
    case DealStatus.PaidToProvider:
      return 'success'
    case DealStatus.Completed:
    case DealStatus.PaymentStarted:
    case DealStatus.UnderDiscussion:
      return 'warning'
    case DealStatus.Declined:
    case DealStatus.Canceled:
    case DealStatus.PaymentFailed:
      return 'danger'
    default:
      return 'primary' //TODO: should never happen
  }
}

export function getTaskStateLabelTranslation(state: Partial<OccurrenceEventState>, canConfirm: boolean): string {
  if (state.dealStatus === undefined) {
    return '';
  }

  if (state.dealStatus === DealStatus.Paid || state.dealStatus === DealStatus.PaidToProvider) {
    if (!state!.completed && !state!.confirmed) {
      return i18n.t('lbl_task_status_active') as string
    }

    if (state!.completed && !state!.confirmed && canConfirm){
      return i18n.t('lbl_task_status_marked_completed') as string
    }

    if (state!.completed && !state!.confirmed && !canConfirm){
      return i18n.t('lbl_task_status_completed') as string
    }

    if (state!.completed && state!.confirmed){
      return i18n.t('lbl_task_status_completed_confirmed') as string
    }

    return ''
  } else {
    return i18n.t(`lbl_status_${state.dealStatus}`) as string
  }
}
