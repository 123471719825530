import Vue, {VNode} from 'vue'
import {Component, Prop, Ref} from 'vue-property-decorator'
import {I18nComponent} from '@/components/I18nComponent'

import {TableRecord} from "@/utils/vue-bootstrap-types"

@Component({name: 'TableComponent'})
export default class extends I18nComponent {
  @Prop({type: Array, required: false}) public readonly items!: object[]
  @Prop(Array) public readonly fields?: object[]
  @Prop(Boolean) public readonly small?: boolean
  @Prop(Boolean) public readonly busy?: boolean
  @Prop(Boolean) public readonly stickyHeader!: boolean
  @Prop({type: String, required: false, default: 'msg_table_empty'}) public readonly emptyKey!: string
  @Prop({type: String, required: false, default: null}) public readonly errorText?: string
  @Prop([String, Array, Function]) public readonly tbodyTrClass?: string | string[]
  @Prop([String, Array, Function]) public readonly theadClass?: string | string[]
  @Prop(String) public readonly sortBy?: string
  @Prop(Boolean) public readonly sortDesc?: boolean
  @Prop({type: String, required: false, default: undefined}) public readonly sortDirection?: string
  @Prop({type: Boolean, required: false, default: true}) public readonly striped?: boolean
  @Prop({type: Boolean, required: false, default: true}) public readonly hover?: boolean
  @Prop({type: Boolean, required: false, default: true}) public readonly bordered?: boolean
  @Prop({type: Boolean, required: false, default: false}) public readonly borderless?: boolean
  @Prop({type: [String, Boolean]}) public readonly responsive?: string | boolean
  @Prop(Number) public readonly perPage?: number
  @Prop(Number) public readonly currentPage?: number
  @Prop(String) public readonly tableClass?: string
  @Prop({type: Boolean, required: false, default: false}) public readonly fixed?: boolean
  @Prop({type: Boolean, required: false, default: false}) public readonly selectable?: boolean
  @Prop({type: String, required: false, default: ''}) public readonly wrapperClass?: string

  @Ref('tableWrapper') public readonly tableWrapper!: Vue

  public onRowClicked(v: TableRecord): void {
    this.$emit('rowClicked', v)
  }
  
  public onRowSelected(v: TableRecord): void {
    this.$emit('rowSelected', v)
  }

  public mounted(): void {
    this.tableWrapper.$el.addEventListener('scroll', this.handleTableScroll)
    window.addEventListener('resize', this.isTablescrollable)
    this.isTablescrollable();
  }

  public beforeDestroy(): void {
    this.tableWrapper.$el.removeEventListener('scroll', this.handleTableScroll)
    window.removeEventListener('resize', this.isTablescrollable)
  }

  private handleTableScroll(): void {
    const tableScrollWidth = this.tableWrapper.$el.scrollWidth;
    const scrollLeft = this.tableWrapper.$el.scrollLeft;
    const navWidth = this.tableWrapper.$el.clientWidth;
    
    if (scrollLeft + navWidth >= tableScrollWidth) {
      this.tableWrapper.$el.classList.add('scroll-end');
    } else {
      this.tableWrapper.$el.classList.remove('scroll-end');
    }

    if (scrollLeft > 0) {
      this.tableWrapper.$el.classList.add('scrolling');
    } else {
      this.tableWrapper.$el.classList.remove('scrolling');
    }
  }
  
  private isTablescrollable(): void {
    const table = this.tableWrapper.$el.querySelector('table')

    if (table === null) {
      return;
    }

    if (table.scrollWidth > this.tableWrapper.$el.clientWidth) {
      this.tableWrapper.$el.classList.add('scrollable');
    } else {
      this.tableWrapper.$el.classList.remove('scrollable');
    }
  }
  
  public render(): VNode {
    return(
      <div class="position-relative">
        <b-table
          ref="tableWrapper"
          class={this.wrapperClass}
          selectable={this.selectable}
          fixed={this.fixed}
          table-class={this.tableClass}
          per-page={this.perPage}
          current-page={this.currentPage}
          responsive={this.responsive}
          borderless={this.borderless}
          bordered={this.bordered}
          striped={this.striped}
          hover={this.hover}
          show-empty={true}
          small={this.small}
          sticky-header={this.stickyHeader}
          items={this.items}
          fields={this.fields}
          busy={this.busy}
          tbody-tr-class={this.tbodyTrClass}
          thead-class={this.theadClass}
          sort-by={this.sortBy}
          sort-desc={this.sortDesc}
          sort-direction={this.sortDirection}
          on-row-clicked={this.onRowClicked}
          on-row-selected={this.onRowSelected}
          scopedSlots={this.$scopedSlots}
        >
          {this.errorText !== null &&
          <template slot="empty">
            <div class="text-center my-2 text-danger">{this.errorText}</div>
          </template>
          }
          {this.errorText === null && this.emptyKey !== undefined &&
          <template slot="empty">
            <div class="text-center my-2">{this.translation(this.emptyKey)}</div>
          </template>
          }
        </b-table>
      </div>
    )
  }
}