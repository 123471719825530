import {Module, VuexModule, Action, getModule} from "vuex-module-decorators"

import rootStore from "@/store"

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedServerResponseError} from "@/utils/errors"
import {DemandsSummary} from "@/_modules/demand/types"
import {ServicesSummary} from "@/_modules/service/types"
import queryString, {StringifiableRecord} from "query-string";
import {OrderDirection, UserId} from "@/types";
import {RequestsSummary} from "@/_modules/request/types";

export interface Criteria {
  userId: string;
  page: number;
  size: number;
  sortBy?: string;
  sortMode?: OrderDirection;
}

export interface PaginationData {
  page: number;
  size: number;
}

export interface Order {
  sortBy: SortBy;
  sortMode: OrderDirection;
}

export enum SortBy {
  name = "name",
  date = "date"
}

function toPaginationQuery(payload: PaginationPayload): Criteria {
  return {
    userId: payload.userId,
    page: payload.pagination.page,
    size: payload.pagination.size,
    sortBy: payload.order.sortBy,
    sortMode: payload.order.sortMode
  }
}


export interface PaginationPayload {
  userId: UserId;
  pagination: PaginationData;
  order: Order;
}


@Module({dynamic: true, namespaced: true, name: "activity", store: rootStore})
export class ProfileModule extends VuexModule {


  @Action({rawError: true})
  public async getServices(payload: PaginationPayload): Promise<ServicesSummary> {
    const resp = await axios.get<ServicesSummary>(`${BackendUrls.services}?${queryString.stringify(toPaginationQuery(payload) as unknown as StringifiableRecord)}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getOrders(payload: PaginationPayload): Promise<DemandsSummary> {
    const resp = await axios.get<DemandsSummary>(`${BackendUrls.demands}?${queryString.stringify(toPaginationQuery(payload) as unknown as StringifiableRecord)}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getRequests(payload: PaginationPayload): Promise<RequestsSummary> {
    const resp = await axios.get<RequestsSummary>(`${BackendUrls.requests}?${queryString.stringify(toPaginationQuery(payload) as unknown as StringifiableRecord)}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getProposals(payload: PaginationPayload): Promise<RequestsSummary> {
    const resp = await axios.get<RequestsSummary>(`${BackendUrls.proposals}?${queryString.stringify(toPaginationQuery(payload) as unknown as StringifiableRecord)}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }
}

export const activityStore = getModule(ProfileModule, rootStore)
